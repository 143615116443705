import { render, staticRenderFns } from "./validateBlock.vue?vue&type=template&id=fc1d648e"
import script from "./validateBlock.vue?vue&type=script&lang=js"
export * from "./validateBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports